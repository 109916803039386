import React from "react";
import styled from "styled-components";

import {
  FaXTwitter,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa6";

import Linka from "./Linka";

const SocialMediaStyles = styled.div`
  .social {
    margin-right: 2rem;

    li {
      margin: 0.5rem;

      a:hover {
        color: var(--gold);
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
    top: 0.125em;
  }
`;

const socials = [
  {
    name: <FaInstagram />,
    link: "https://www.instagram.com/family.i.love/",
  },
  {
    name: <FaYoutube />,
    link: "https://www.youtube.com/@familyilove",
  },
  {
    name: <FaTiktok />,
    link: "https://www.tiktok.com/@familyiloveband",
  },
  {
    name: <FaFacebookF />,
    link: "https://www.facebook.com/FamilyILove.Band",
  },
  {
    name: <FaXTwitter />,
    link: "https://twitter.com/FamilyILoveBand",
  },
];

export default function SocialMedia() {
  return (
    <SocialMediaStyles>
      <ul className="social">
        {socials.map((social, i) => (
          <li key={i}>
            <Linka
              to={social.link}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className={social.class}
            >
              {social.name}
            </Linka>
          </li>
        ))}
      </ul>
    </SocialMediaStyles>
  );
}
