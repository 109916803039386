import React from "react";
import styled from "styled-components";

import SocialMedia from "../utility/SocialMedia";
import Linka from "../utility/Linka";

const FooterStyles = styled.footer`
  background: var(--green);
  border-top: 1px solid var(--tan);
  color: var(--darker-tan);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  .social {
    margin-top: 1.5rem;

    li {
      margin: 0.5rem;
    }
  }

  ul {
    display: flex;
    list-style: none;

    @media (max-width: 900px) {
      margin: 0;
    }

    &.copyright li {
      white-space: nowrap;
    }

    li {
      margin: 1rem;
      font-size: 1rem;

      a {
        color: var(--tan);

        &:hover {
          color: var(--gold);
        }
      }
    }
  }

  @media (max-width: 650px) {
    display: grid;
    grid-template-columns: 1fr;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <ul className="copyright">
        <li>&copy; {new Date().getFullYear()} Family I Love</li>
      </ul>

      <ul className="terms">
        <li>
          <Linka to="/terms-and-conditions" rel="nofollow">
            Terms And Conditions
          </Linka>
        </li>

        <li>
          <Linka to="/privacy-policy" rel="nofollow">
            Privacy Policy
          </Linka>
        </li>
      </ul>

      <SocialMedia />
      <div></div>
    </FooterStyles>
  );
}
