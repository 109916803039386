import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import Logo from "../Logo";
import Linka from "../../utility/Linka";
import DesktopMenu from "./DesktopMenu";
import SocialMedia from "../../utility/SocialMedia";

const DesktopNavStyles = styled.nav`
  display: grid;
  color: var(--tan);
  grid-template-columns: 1fr;
  position: relative;

  .logo-url {
    display: grid;
    justify-content: center;
    background-color: var(--green);
    margin-right: 0;
  }

  @media (max-width: 1280px) {
    .logo-url {
      grid-row: 1;
    }
  }

  .social-urls .social {
    list-style-type: none;
    display: flex;
    position: absolute;
    top: -7px;
    right: 0;
    margin-right: 1rem;

    li {
      margin: 0.2rem 0.5rem;
    }
  }

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--tan);

    @media (max-width: 1300px) {
      justify-content: left;
    }

    ul {
      list-style-type: none;
      display: flex;
      align-items: baseline;
      margin-bottom: 0;

      li {
        margin-bottom: 0.8rem;
        display: grid;
      }
    }

    .category {
      display: flex;
      align-items: flex-start;
      margin-right: 2rem;

      font-family: var(--text-font), sans-serif;
      font-size: 1.2rem;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .navbar a {
    font-size: 1rem;
    font-family: var(--text-font), sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    text-decoration: none;
    color: var(--tan);
    margin-right: 2rem;
    border-bottom: 0px solid rgba(0, 0, 0, 0);

    &:after {
      transition: all ease-in-out 0.5s;
      background: none repeat scroll 0 0 var(--tan);
      content: "";
      display: block;
      height: 2px;
      width: 0;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .navbar a.active::after {
    background: transparent;
  }

  @media (max-width: 800px) {
    a,
    .navbar .category {
      font-size: 1rem;
    }
  }

  .logo-url {
    &:hover:after {
      width: 0;
    }
  }

  .arrow {
    width: 15px;
    height: 15px;
    padding-left: 5px;
  }

  .active {
    border-bottom: 2px solid !important;
  }

  .nested-menu {
    position: absolute;
    bottom: -37px;
    left: 0;
    width: 100%;
    height: 3rem;
    background-color: var(--black);
    justify-content: center;

    li a {
      font-size: 1rem;
    }
  }
`;

export default function NavbarDesktop({ menus, location }) {
  const [posInNav, setPosInNav] = useState([0]);

  const UlRef = useRef();

  useEffect(() => {
    const handleClickOut = (e) => {
      if (!UlRef.current.contains(e.target) && posInNav.length > 1) {
        setPosInNav([0]);
      }
    };

    document.addEventListener("click", handleClickOut);

    return () => {
      document.removeEventListener("click", handleClickOut);
    };
  }, [UlRef, posInNav]);

  return (
    <DesktopNavStyles>
      {location.pathname !== "/" &&
        location.pathname !== "/the-royal-family/" && (
          <Linka to="/" className="logo-url">
            {" "}
            <Logo />
          </Linka>
        )}

      <div className="social-urls">
        <SocialMedia />
      </div>

      <div className="navbar">
        <ul
          ref={(NewUlRef) => {
            UlRef.current = NewUlRef;
          }}
        >
          <li>
            <Linka
              to="/"
              className={location.pathname === "/" ? "active" : ""}
              onClick={() => setPosInNav([0])}
            >
              HOME
            </Linka>
          </li>

          <DesktopMenu
            data={menus}
            level={[0]}
            posInNav={posInNav}
            setPosInNav={setPosInNav}
            location={location}
          />

          <li>
            <Linka
              to="https://shop.familyilove.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              SHOP
            </Linka>
          </li>
        </ul>
      </div>
    </DesktopNavStyles>
  );
}
